import React, { useEffect, useState } from 'react';
import { Input, Select } from 'antd';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { useTranslation } from 'react-i18next';

const { Search } = Input;

type Props = {
  onChangeFilterField: (field: string) => void;
  onChangeText: (field: string) => void;
};
const SearchLocation: React.FC<Props> = ({ onChangeFilterField, onChangeText }) => {
  const { t } = useTranslation();

  const [searchBy, setSearchBy] = useState('name');

  const { loading } = useSelector((state: IApplicationState) => ({
    loading: state.location.loading,
  }));

  useEffect(() => {
    onChangeFilterField(searchBy);
    // eslint-disable-next-line
  }, [searchBy]);

  const selectBefore = (
    <Select onChange={(filter: string) => setSearchBy(filter)} defaultValue={searchBy} className="select-before">
      <Select.Option value="name">{t('Name')}</Select.Option>
    </Select>
  );

  const onSearchHandler = (search: string) => {
    onChangeText(search);
  };

  return (
    <div className={'phone-button-margin-full'}>
      <Search style={{ width: '100%' }} loading={loading} addonBefore={selectBefore} onSearch={onSearchHandler} />
    </div>
  );
};

export default SearchLocation;
