import { ConnectedRouter } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { APP_AUTH_DATA_TOKEN } from './const';
import { AppContextProvider } from './context/app-context';
import { history } from './store';
import { noToken, onLoadRequest } from './store/auth/actions';

import { Router } from './Router';

export const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem(APP_AUTH_DATA_TOKEN);
    if (token) {
      dispatch(onLoadRequest(token));
    } else {
      dispatch(noToken());
    }
  }, [dispatch]);

  return (
    <ConnectedRouter history={history}>
      <AppContextProvider>
        <Router />
      </AppContextProvider>
    </ConnectedRouter>
  );
};
