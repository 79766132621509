import styled from 'styled-components';

export const ColorBox = styled.div`
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background: ${(props) => props.color};
`;
export const ColorBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;
