import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyledLoadingOutlined } from '../../styled';
const { Title } = Typography;

const Wrapper = styled.div``;

const StyledTitle = styled(Title)`
  text-align: center;
  font-weight: 200 !important;
`;

type Props = {};

const Loader: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StyledLoadingOutlined />
      <StyledTitle level={2}>{t('Loading')}...</StyledTitle>
    </Wrapper>
  );
};

export default Loader;
