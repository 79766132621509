import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Title } from '../../styled';
import { Link, useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const Home: React.FC = () => {
  const history = useHistory();

  useEffect(() => void history.push('/auth'), [history]);

  return (
    <Wrapper>
      <Title>Home WIP</Title>
      <Title>
        <Link to="/auth">auth</Link>
      </Title>
    </Wrapper>
  );
};
