import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { MainLayout } from './layout/MainLayout';
import { PublicLayout } from './layout/PublicLayout';
import { Auth } from './views/Auth/Auth';
import { Home } from './views/Home/Home';
import { Locations } from './views/Locations/Locations';

export const Router = () => (
  <Switch>
    <ProtectedRoute
      Component={Locations}
      Layout={MainLayout}
      path="/locations"
    />
    <ProtectedRoute
      Component={Auth}
      Layout={PublicLayout}
      captcha
      path="/auth"
    />
    <ProtectedRoute
      Component={Home}
      Layout={PublicLayout}
      path="/"
    />
  </Switch>
);
