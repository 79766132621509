import { Reducer } from 'react';
import { AnyAction } from 'redux';
import { AppActionTypes, AppBreadcrumbState, AppState } from './types';

const initialState: AppState = {
  breadcrumbs: [],
};

const setBreadcrumbs = (state: AppState, breadcrumbs: AppBreadcrumbState[]): AppState => ({ ...state, breadcrumbs });

export const AppReducer: Reducer<AppState, AnyAction> = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.SET_BREADCRUMBS:
      return setBreadcrumbs(state, action.payload);
    default:
      return state;
  }
}
