import React, { useState } from 'react';
import styled from 'styled-components';
import LoginForm from './LoginForm';
import { useTranslation } from 'react-i18next';
import { APP_LANG, flags, languages } from '../../const';
import { Select } from 'antd';
import * as moment from 'moment';
import { momentLangSetup } from '../../i18n';

const Wrapper = styled.div`
  padding-top: 5em;
`;

const FormWrapper = styled.div`
  padding-top: 50px;
`;

export const Auth: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [lang] = useState(localStorage.getItem(APP_LANG) || 'en');

  return (
    <Wrapper>
      <Select
        defaultValue={lang}
        bordered={false}
        style={{
          alignItems: 'center',
          float: 'right',
          marginRight: '30px',
          width: 140,
        }}
        onChange={(lang: any) => {
          localStorage.setItem(APP_LANG, lang as string);
          //@ts-ignore
          moment.locale(lang, momentLangSetup[lang]);
          i18n.changeLanguage(lang);
          window.location.reload();
        }}
      >
        {languages.map((language) => (
          <Select.Option key={language} value={language} label={t(`[Language] ${language}`)}>
            {t(`[Language] ${language}`)}{' '}
            {
              //@ts-ignore
              flags[language as any]
            }
          </Select.Option>
        ))}
      </Select>

      <FormWrapper>
        <LoginForm />
      </FormWrapper>
    </Wrapper>
  );
};
