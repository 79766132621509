import { Drawer } from 'antd';
import React from 'react';
import { FullLayout } from '../../styled';
import { MainMenu } from '../MainMenu/MainMenu';

export const AppSidebar: React.FC<AppSidebarProps> = props => window.innerWidth <= 800 ? (
  <Drawer
    className="main-drawer"
    closable={false}
    key="left"
    placement="left"
    style={{ padding: 0 }}
    visible={props.collapsed}
    width={260}
    onClose={() => props.onToggle()}
  >
    <MainMenu />
  </Drawer>
) : (
  <FullLayout.Sider
    collapsed={props.collapsed}
    collapsible
    width={260}
    onCollapse={() => props.onToggle()}
  >
    <MainMenu />
  </FullLayout.Sider>
);

type AppSidebarProps = {
  collapsed: boolean;
  onToggle: () => void;
};
