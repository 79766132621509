import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { AppReducer } from './app/reducer';
import { AuthReducer } from './auth/reducer';
import { LocationReducer } from './location/reducer';

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app: AppReducer,
    auth: AuthReducer,
    location: LocationReducer,
  });

export default rootReducer;
