import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { env } from '../../const/env';
import { IApplicationState } from '../../models/IApplicationState';
import Loader from '../sharedComponents/Loader';
import NoPermissionPage from '../sharedComponents/NoPermissionPage';

export const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
  const { loading, user } = useSelector((state: IApplicationState) => ({
    loading: state.auth.loading,
    user: state.auth.user,
  }));

  return (
    <Route
      path={props.path}
      render={(routeProps: RouteComponentProps) => {
        if (loading && user === null) {
          return <Loader />;
        }

        if (props.captcha) {
          return (
            <GoogleReCaptchaProvider reCaptchaKey={env.captchaKey}>
              <props.Layout>
                <props.Component {...routeProps} />
              </props.Layout>
            </GoogleReCaptchaProvider>
          );
        }

        if (user) {
          const hasPermission = true;
          if (hasPermission) {
            return (
              <props.Layout>
                <props.Component {...routeProps} />
              </props.Layout>
            );
          } else {
            return <NoPermissionPage requiredRoles={[]} />;
          }
        } else {
          return <Redirect to="/auth?login" />;
        }
      }}
    />
  );
};

interface ProtectedRouteProps {
  Component: React.FC<RouteComponentProps>;
  Layout: React.FC;
  captcha?: boolean;
  path: string;
}
