import React from 'react';
import axios, { AxiosInstance } from 'axios';
import { env } from '../const/env';
import store from '../store';
import { logoutSuccess } from '../store/auth/actions';
import {
  APP_AUTH_DATA_REFRESH,
  APP_AUTH_DATA_REFRESH_USER,
  APP_AUTH_DATA_TOKEN,
  APP_AUTH_DATA_TOKEN_USER,
  APP_LANG,
} from '../const';
import { notification, Button } from 'antd';
import i18n from '../i18n';

const instance: AxiosInstance = axios.create({
  baseURL: env.apiUrl,
});

instance.interceptors.request.use(
  (config) => {
    config.baseURL = env.apiUrl + (localStorage.getItem(APP_LANG) || 'en');
    const token = localStorage.getItem(
      window.location.pathname === '/widget' ? APP_AUTH_DATA_TOKEN_USER : APP_AUTH_DATA_TOKEN
    );
    if (token !== null) config.headers.authorization = 'Bearer ' + token;
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    try {
      const originalRequest = error.config;

      if (error.response && error.response.data) {
        console.log(error.response.data);

        if (error.response.data.message === 'Token is not in white list') {
          store.dispatch(logoutSuccess());
          return Promise.reject(error);
        }

        if (error.response.data.message === 'jwt expired' && !originalRequest._retry) {
          if (originalRequest.url === '/refresh-token') {
            localStorage.removeItem(APP_AUTH_DATA_REFRESH);
            localStorage.removeItem(APP_AUTH_DATA_TOKEN);
            window.location.reload();
          }

          const refreshToken = localStorage.getItem(
            window.location.pathname === '/widget' ? APP_AUTH_DATA_REFRESH_USER : APP_AUTH_DATA_REFRESH
          );

          if (refreshToken) {
            const res = await instance.post('/refresh-token', { refreshToken });

            if (res.status === 201) {
              localStorage.setItem(
                window.location.pathname === '/widget' ? APP_AUTH_DATA_TOKEN_USER : APP_AUTH_DATA_TOKEN,
                res.data.token
              );

              instance.defaults.headers.authorization = `Bearer ${res.data.token}`;
              return instance(originalRequest);
            }
          }
          originalRequest._retry = true;
        } else {
          if (error.response.config.url !== 'user/on-load') {
            if (error.response.config.url !== 'master/on-load') {
              if (error.response.data.message === 'Your service limit is exceeded') {
                const btn = (
                  <a href="/billing">
                    <Button type="primary" size="small">
                      {i18n.t('Go To Biiling')}
                    </Button>
                  </a>
                );
                notification.error({
                  message: `${i18n.t('Error')}`,
                  description: i18n.t(error.response.data.message),
                  duration: 100000,
                  btn,
                });
              } else {
                notification.error({
                  message: `${i18n.t('Error')}`,
                  description: i18n.t(error.response.data.message),
                  duration: 5,
                });
              }
            }
          }
          return Promise.reject(error);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
);

export default instance;
