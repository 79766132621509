import { AuthActionTypes } from './types';
import { takeEvery, put } from 'redux-saga/effects';
import AuthService from '../../services/auth-service';
import { loginFailure, loginSuccess, logoutFailure, logoutSuccess } from './actions';
import { IAuthCredential } from '../../models/IAuthCredential';
import { APP_AUTH_DATA_REFRESH, APP_AUTH_DATA_TOKEN, APP_LANG, USER_ID } from '../../const';
import MasterService from '../../services/master-service';
import { push } from 'react-router-redux';
import * as moment from 'moment';
import i18n from '../../i18n';
import { notification } from 'antd';
const authService = new AuthService();
const masterService = new MasterService();

//TODO catch response fail
function* loginWorker(action: { type: string; payload: IAuthCredential }) {
  try {
    const response = yield masterService.login(action.payload);
    if (response.data.user.role !== 'sudo') {
      throw new Error('Access denied.');
    }
    localStorage.setItem(APP_AUTH_DATA_TOKEN, response.data.token);
    localStorage.setItem(APP_AUTH_DATA_REFRESH, response.data.refreshToken);
    localStorage.setItem(USER_ID, response.data.user._id);
    yield put(loginSuccess(response.data.user));
    yield put(push('/locations'));
    const lang = window.localStorage.getItem(APP_LANG) || 'en';
    moment.locale(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem(APP_LANG, lang);

    setTimeout(() => {
      const recaptchaElm: any = document.getElementsByClassName('grecaptcha-badge');
      if (recaptchaElm && recaptchaElm[0]) recaptchaElm[0].style.visibility = 'hidden';
    }, 500);
  } catch (e) {
    if (e.message === 'Access denied.') {
      notification.error({
        message: `${i18n.t('Error')}`,
        description: i18n.t('Access denied.'),
        duration: 100000,
      });
    }
    console.log(e);
    yield put(loginFailure(e.message));
  }
}

function* onLoadWorker(action: { type: string; payload: string }) {
  try {
    const response = yield masterService.onLoad(action.payload);
    yield put(loginSuccess(response.data.user));
    yield put(push('/locations'));
    const lang = window.localStorage.getItem(APP_LANG) || 'en';
    moment.locale(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem(APP_LANG, lang);
  } catch (e) {
    console.log(e);
    yield put(loginFailure(e.message));
  }
}

function* logoutWorker() {
  try {
    yield authService.logout(localStorage.getItem(APP_AUTH_DATA_TOKEN) as string);
    localStorage.removeItem(APP_AUTH_DATA_TOKEN);
    localStorage.removeItem(APP_AUTH_DATA_REFRESH);
    localStorage.removeItem(USER_ID);
    yield put(push(`/`));
    yield put(logoutSuccess());
  } catch (e) {
    yield put(push(`/`));
    localStorage.removeItem(APP_AUTH_DATA_TOKEN);
    localStorage.removeItem(APP_AUTH_DATA_REFRESH);
    localStorage.removeItem(USER_ID);
    yield put(logoutFailure());
  }
}

export function* watchAuth() {
  yield takeEvery(AuthActionTypes.LOGIN_REQUEST, loginWorker);
  yield takeEvery(AuthActionTypes.ON_LOAD_REQUEST, onLoadWorker);
  yield takeEvery(AuthActionTypes.LOGOUT_REQUEST, logoutWorker);
}

const authWatchers: any = [watchAuth()];

export default authWatchers;
