import { LogoutOutlined, TeamOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { logoutRequest } from '../../store/auth/actions';

export const MainMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const handleLogOut = () => dispatch(logoutRequest());

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultOpenKeys={[location.pathname]}
      defaultSelectedKeys={[location.pathname + location.search]}
      style={{ borderRight: 0 }}
    >
      <Menu.Item icon={<TeamOutlined />} key="/locations">
        <Link to="/locations">{t('Locations')}</Link>
      </Menu.Item>
      <Menu.Item onClick={handleLogOut} icon={<LogoutOutlined />} key="/logout">
        {t('Logout')}
      </Menu.Item>
    </Menu>
  );
};
