export interface AppState {
  breadcrumbs: AppBreadcrumbState[];
}

export interface AppBreadcrumbState {
  label: string;
  link?: string;
}

export enum AppActionTypes {
  SET_BREADCRUMBS = '@@app/SET_BREADCRUMBS',
}
