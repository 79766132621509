import React, { createContext, useState } from 'react';

type Props = {
  children: React.ReactNode;
};

export interface Context {
  ws: WebSocket | null;
  mainContainerHeight: number;
  mainContainerWidth: number;
  setContext: (settings: { ws?: WebSocket; mainContainerHeight?: number; mainContainerWidth?: number }) => void;
}

const initialContext: Context = {
  ws: null,
  mainContainerHeight: 0,
  mainContainerWidth: 0,
  setContext: (): void => {
    throw new Error('setContext function must be overridden');
  },
};

const AppContext = createContext<Context>(initialContext);

const AppContextProvider = ({ children }: Props): JSX.Element => {
  const [contextState, _setContext] = useState<Context>(initialContext);

  const setContext = (settings: { ws?: WebSocket; mainContainerHeight?: number; mainContainerWidth?: number }) => {
    _setContext((prevState) => {
      return { ...prevState, ...settings };
    });
  };

  return <AppContext.Provider value={{ ...contextState, setContext }}>{children}</AppContext.Provider>;
};

export { AppContext, AppContextProvider };
