import { LoginOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Statistic, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { clickTargetBlankLink, copyToClipboard } from '../../services/utils';
import { APP_AUTH_DATA_TOKEN, minTableCellWidth } from '../../const';
import { ILocation } from '../../models/ILocation';
import { searchLocationsRequest } from '../../store/location/actions';
import SearchLocation from './SearchLocation';
import { setBreadcrumbs } from '../../store/app/actions';
import { createBreadcrumb } from '../../store/app/utils';

export const Locations = () => {
  const dispatch = useDispatch();
  const [searchBy, setSearchBy] = useState('name');
  const [search, setSearch] = useState('');

  const { t } = useTranslation();
  // eslint-disable-next-line

  const [queryData, setQueryData] = useState({
    query: {} as any,
    sort: {} as any,
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 200,
    showQuickJumper: true,
  });

  const { loading, locations, total } = useSelector((state: IApplicationState) => ({
    loading: state.location.loading,
    locations: state.location.locations,
    total: state.location.total,
  }));

  useEffect(() => {
    dispatch(setBreadcrumbs([createBreadcrumb(t('Locations'), '/locations')]));
  }, [dispatch, t]);

  useEffect(() => {
    dispatch(
      searchLocationsRequest(
        {
          offset: pagination.pageSize * (pagination.current - 1),
          limit: pagination.pageSize,
        },
        { key: searchBy, value: search },
        queryData
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPagination({ ...pagination, total });
    // eslint-disable-next-line
  }, [loading, total, locations]);

  const handleLogin = (masterId: string) => {
    const token = localStorage.getItem(APP_AUTH_DATA_TOKEN);
    clickTargetBlankLink(`https://app.skeedee.com/auth?login&masterId=${masterId}&token=${token}`);
  };

  const tableValue = (val: any) => (
    <div
      onClick={() => {
        copyToClipboard(val);
        message.success(`${t('Copied')}: ${val}`);
      }}
    >
      <Tooltip placement="topLeft" title={val}>
        {val}
      </Tooltip>
    </div>
  );

  const columns: any = [
    {
      render: (val: any) => (
        <Tooltip placement="top" title={t(`Sign in as the location's owner`)}>
          <Button
            icon={<LoginOutlined />}
            shape="circle"
            type="primary"
            onClick={() => handleLogin(val.owner)}
          />
        </Tooltip>
      )
    },
    {
      title: t('logo'),
      dataIndex: 'avatarUrl',
      render: (val: any) => (val ? <img style={{ width: '30px', height: '30px' }} alt='avatar' src={val} /> : <div />),
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      render: tableValue,
      sorter: true,
    },
    {
      title: t('Country'),
      dataIndex: 'country',
      render: tableValue,
      sorter: true,
    },
    {
      title: t('Created'),
      dataIndex: 'createdAt',
      render: (val: any) => <div>{new Date(val).toLocaleString()}</div>,
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'],
    },
    {
      title: t('Employees'),
      dataIndex: 'masters',
      render: (val: any) => <div>{val.length}</div>,
      width: minTableCellWidth,
    },
    {
      title: t('Clients'),
      dataIndex: 'clients',
      render: (val: any) => <div>{val.length}</div>,
      width: minTableCellWidth,
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let query: any = {};

    console.log(sorter);
    let sort: any = {
      [sorter.field]: sorter.order === 'descend' ? -1 : 1,
    };

    if (!sorter.order) sort = {};

    Object.keys(filters).forEach((key) => {
      switch (key) {
        case 'subscription':
        case 'ban':
          if (Array.isArray(filters[key]))
            if (filters[key].length === 1) {
              query[key] = filters[key][0] === 'true';
            }
          break;
        case 'count':
          if (filters['noReservation']) {
            query['noReservation'] = filters['noReservation'][0] === 'true';
          }
          break;
      }
    });

    setQueryData({ sort, query });
    setPagination(pagination);
    dispatch(
      searchLocationsRequest(
        {
          offset: pagination.pageSize * (pagination.current - 1),
          limit: pagination.pageSize,
        },
        { key: searchBy, value: search },
        { sort, query }
      )
    );
  };

  const onChangeFilterField = (field: string) => {
    setSearchBy(field);
  };
  const onChangeText = (field: string) => {
    dispatch(
      searchLocationsRequest(
        {
          limit: 10,
          offset: 0,
        },
        {
          key: searchBy,
          value: field,
        },
        queryData
      )
    );

    setSearch(field);
    setPagination({
      current: 1,
      pageSize: 10,
      total: 200,
      showQuickJumper: true,
    });
  };

  return (
    <>
      <Row>
        <Col span={window.innerWidth <= 800 ? 24 : 16} offset={window.innerWidth <= 800 ? 0 : 1}>
          <SearchLocation onChangeText={onChangeText} onChangeFilterField={onChangeFilterField} />
        </Col>
        <Col span={24} style={{ marginTop: 16 }}>
          <Statistic className={'mobile-left-padding'} title={t('Total locations')} value={total} />
        </Col>
        <Col span={24} style={{ marginTop: 16 }}>
          <Table
            loading={loading}
            onChange={handleTableChange}
            rowKey={(item: ILocation) => item._id}
            pagination={pagination}
            columns={columns}
            dataSource={locations}
            sortDirections={['ascend', 'descend', 'ascend']}
          />
        </Col>
      </Row>
    </>
  );
};
