import { MenuOutlined } from '@ant-design/icons';
import { Breadcrumb, Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IApplicationState } from '../../models/IApplicationState';
import { AppBreadcrumbState } from '../../store/app/types';
import './AppHeader.css';

export const AppHeader: React.FC<AppHeaderProps> = props => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const { breadcrumbs, email, role } = useSelector((state: IApplicationState) => ({
    breadcrumbs: state.app.breadcrumbs,
    email: state.auth.user?.email,
    role: state.auth.user?.role as string,
  }));

  const handleBreadcrumbClick = (breadcrumb: AppBreadcrumbState) => {
    if (breadcrumb.link && breadcrumb.link !== location.pathname) {
      history.push(breadcrumb.link);
    }
  };

  const handlePathnameChunkClick = (pathname: string, index: number) => {
    if (!index && location.pathname !== pathname) {
      history.push(pathname);
    }
  };

  return (
    <Row className="app-header" align="middle" justify="space-between">
      <Col>
        <Breadcrumb style={{ margin: window.innerWidth <= 800 ? '11px 24px 11px 0' : '11px 24px' }}>
          {window.innerWidth <= 800 && (
            <Breadcrumb.Item>
              <MenuOutlined className="menu-button" onClick={() => props.onBurgerClick()} />
            </Breadcrumb.Item>
          )}
          {breadcrumbs.length ? breadcrumbs.map((breadcrumb, index) => (
            <Breadcrumb.Item key={index} onClick={() => handleBreadcrumbClick(breadcrumb)}>
              {breadcrumb.label}
            </Breadcrumb.Item>
          )) : location.pathname
              .split('/')
              .filter(chunk => !!chunk)
              .map((chunk, index) => (
                <Breadcrumb.Item key={index} onClick={() => handlePathnameChunkClick(`/${chunk}`, index)}>
                  {t(chunk)}
                </Breadcrumb.Item>
              ))
          }
        </Breadcrumb>
      </Col>
      <Col>
        {email} / {t(`[Role] ${role}`)}
      </Col>
    </Row>
  );
};

type AppHeaderProps = {
  onBurgerClick: () => void;
};
