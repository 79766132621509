import React, { useContext, useEffect, useRef, useState } from 'react';
import { Layout } from 'antd';
import { FullLayout, MainContent, MainContentLayout } from '../styled';
import { AppContext } from '../context/app-context';
import { AppSidebar } from '../components/AppSidebar/AppSidebar';
import { AppHeader } from '../components/AppHeader/AppHeader';

export const MainLayout: React.FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const mainRef = useRef(null);
  const appContext = useContext(AppContext);

  const upperCaseFirstLetter = (str: string): string => {
    var firstLetter = str.substr(0, 1);
    return firstLetter.toUpperCase() + str.substr(1);
  };

  useEffect(() => {
    const [, _path] = window.location.pathname.split('/');
    document.title = `Skeedee ${upperCaseFirstLetter(_path)}`;
    if (mainRef) {
      setTimeout(() => {
        try {
          const e: any = document.getElementsByClassName('ant-layout-content');
          const mainContainerHeight = e[0].clientHeight - 96;
          const mainContainerWidth: number = e[0].clientWidth;
          document.documentElement.style.setProperty(
            '--main-wrapper-heigth',
            mainContainerHeight - (window.innerWidth <= 800 ? 33 : 0) + 'px'
          );
          appContext.setContext({
            mainContainerHeight: mainContainerHeight - window.innerWidth <= 800 ? 33 : 0,
            mainContainerWidth,
          });
        } catch (e) {
          console.log(e);
        }
      }, 1);

      window.onresize = () => {
        setTimeout(() => {
          try {
            const e: any = document.getElementsByClassName('ant-layout-content');
            const mainContainerHeight = e[0].clientHeight - 96;
            const mainContainerWidth: number = e[0].clientWidth;
            document.documentElement.style.setProperty(
              '--main-wrapper-heigth',
              mainContainerHeight - (window.innerWidth <= 800 ? 33 : 0) + 'px'
            );
            appContext.setContext({
              mainContainerHeight: mainContainerHeight - (window.innerWidth <= 800 ? 33 : 0),
              mainContainerWidth,
            });
          } catch (e) {
            console.log(e);
          }
        }, 1);
      };
    }
    // eslint-disable-next-line
  }, [mainRef]);

  return (
    <FullLayout>
      <Layout>
        <AppSidebar collapsed={collapsed} onToggle={() => setCollapsed(!collapsed)} />
        <MainContentLayout>
          <AppHeader onBurgerClick={() => setCollapsed(true)} />
          <MainContent ref={mainRef}>{children}</MainContent>
        </MainContentLayout>
      </Layout>
    </FullLayout>
  );
};
