export const APP_AUTH_DATA_TOKEN = 'BBOKING_DATA_TOKEN';
export const APP_AUTH_DATA_TOKEN_USER = 'BBOKING_DATA_TOKEN_USER';
export const APP_AUTH_DATA_REFRESH = 'BBOKING_DATA_REFRESH';
export const USER_ID = 'USER_ID';
export const APP_AUTH_DATA_REFRESH_USER = 'BBOKING_DATA_REFRESH_USER';
export const APP_TYPE = 'APP_TYPE';
export const APP_LANG = 'BBOKING_APP_LANG';
export const NEW_LOCATION_DATA = 'BBOKING_NEW_LOCATION_DATA';

export const dateFormat = 'DD-MM-YYYY';

export const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const applyWhen = ['firstTime', 'every', 'afterQuantity', 'oneTime', 'afterAmount', 'everyTime'];

export const reservationStatuses = ['created', 'inProgress', 'done', 'canceled', 'needUpdate'];

export const languages = [
  'uk',
  'ru',
  // 'it', 'pl',
  'en',
];

export const flags = {
  uk: '🇺🇦',
  ru: '🇷🇺',
  en: '🇺🇸',
};

export const userClass = ['silver', 'gold', 'platinum'];

export const categories: any = {
  en: [
    'Barbershop',
    'Beauty saloon',
    'Barbershop',
    'SPA',
    "Children's hairdresser",
    'Tattoo',
    'Solarium',
    'Cosmetology',
    'Manicure',
    'Eyebrows and eyelashes',
    'Medical Center',
    'Dentistry',
    'Veterinary',
    'Psychotherapy and Psychology',
    'Alternative medicine',
    'Hospital',
    'Polyclinic',
    'Analyzes',
    'Diagnostic center',
    'Gynecology',
    'Sport',
    'Fitness',
    'School of martial arts',
    'Sport school',
    'Dancing school',
    'Tennis and squash',
    'Pool',
    'EMS',
    'Private trainer',
    'Trampoline center',
    'Yoga',
    'Training',
    'Driving school',
    "Children's section",
    'Tutor',
    'School of Music',
    'Language school',
    'Leisure and recreation',
    'Massage parlor',
    'Quests',
    'Sauna',
    'Bowling',
    'Cybersport',
    'Restaurant',
    'Salt caves',
    'Karting',
    'Entertainment',
    'Domestic services',
    'Photo studio',
    'Service center',
    'Rental',
    'Studio',
    'Dry cleaning',
    'Cleaning',
    'Interior Design',
    'Repair of equipment',
    'Pet services',
    'Car wash',
    'Car service',
    'Tire fitting',
    'Car showroom',
    'Auto-studio',
    'Tuning center',
    'Detailing',
    'Private mechanic',
    'Furniture and interior',
    'Shopping center',
    'Boutique and show-room',
    'Bridal salon',
    'Law Firm',
    'Law Office',
    'Coworking',
    'Accounting and audit',
    'Organization of events',
    'Notary',
  ],
  ru: [
    'Барбершоп',
    'Салон красоты',
    'Парикмахерская',
    'SPA',
    'Детская парикмахерская',
    'Tattoo',
    'Солярий',
    'Косметология',
    'Ногтевой сервис',
    'Брови и ресницы',
    'Медицинский центр',
    'Стоматология',
    'Ветеринария',
    'Психотерапия и психология',
    'Альтернативная медицина',
    'Больница',
    'Поликлиника',
    'Анализы',
    'Диагностический центр',
    'Женская консультация',
    'Спорт',
    'Фитнес',
    'Школа единоборств',
    'Спортивная школа',
    'Школа танцев',
    'Теннис и сквош',
    'Бассейн',
    'EMS',
    'Частный тренер',
    'Батутный центр',
    'Йога',
    'Обучение',
    'Автошкола',
    'Детская секция',
    'Репетитор',
    'Музыкальная школа',
    'Языковая школа',
    'Досуг и отдых',
    'Массажный салон',
    'Квесты',
    'Сауна',
    'Боулинг',
    'Киберспорт',
    'Ресторан',
    'Соляные пещеры',
    'Картинг',
    'Развлечения',
    'Бытовые услуги',
    'Фотостудия',
    'Сервисный центр',
    'Прокат',
    'Ателье',
    'Химчистка',
    'Клининг',
    'Дизайн интерьера',
    'Ремонт техники',
    'Зооуслуги',
    'Автомойка',
    'Автосервис',
    'Шиномонтаж',
    'Автосалон',
    'Авто-ателье',
    'Тюнинг-центр',
    'Детейлинг',
    'Частный механик',
    'Мебель и интерьер',
    'Торговый центр',
    'Бутик и show-room',
    'Свадебный салон',
    'Юридическая компания',
    'Адвокатское бюро',
    'Коворкинг',
    'Бухгалтерия и аудит',
    'Организация мероприятий',
    'Нотариус',
  ],
  uk: [
    'Барбершоп',
    'Салон краси',
    'Перукарня',
    'SPA',
    'Дитяча перукарня',
    'Tattoo',
    'Солярій',
    'Косметологія',
    'Ногтевой сервис',
    'Брови і вії',
    'Медичний центр',
    'Стоматологія',
    'Ветеринарія',
    'Психотерапія та психологія',
    'Альтернативна медицина',
    'Лікарня',
    'Поліклініка',
    'Аналізи',
    'Діагностичний центр',
    'Жіноча консультація',
    'Спорт',
    'Фітнес',
    'Школа єдиноборств',
    'Спортивна школа',
    'Школа танців',
    'Теніс і сквош',
    'Басейн',
    'EMS',
    'Приватний тренер',
    'Батутними центр',
    'Йога',
    'Навчання',
    'Автошкола',
    'Дитяча секція',
    'Репетитор',
    'Музична школа',
    'Мовна школа',
    'Дозвілля та відпочинок',
    'Масажний салон',
    'Квести',
    'Сауна',
    'Боулінг',
    'Кіберспорт',
    'Ресторан',
    'Соляні печери',
    'Картинг',
    'Розваги',
    'Побутові послуги',
    'Фотостудія',
    'Сервісний центр',
    'Прокат',
    'Ательє',
    'Хімчистка',
    'Клінінг',
    "Дизайн інтер'єра",
    'Ремонт техніки',
    'Зоопослуги',
    'Автомийка',
    'Автосервіс',
    'Шиномонтаж',
    'Автосалон',
    'Авто-ательє',
    'Тюнінг-центр',
    'Детейлінг',
    'Приватний механік',
    "Меблі й інтер'єр",
    'Торговий центр',
    'Бутик і show-room',
    'Весільний салон',
    'Юридична компанія',
    'Адвокатське бюро',
    'Коворкінг',
    'Бухгалтерія і аудит',
    'Організація заходів',
    'Нотаріус',
  ],
  it: [
    'Barbiere',
    'Salone di bellezza',
    'Barbiere',
    'SPA',
    'Parrucchiere per bambini',
    'Tatuaggio',
    'Solarium',
    'Cosmetologia',
    'Manicure',
    'Sopracciglia e ciglia',
    'Centro medico',
    'Odontoiatria',
    'Veterinario',
    'Psicoterapia e Psicologia',
    'Medicina alternativa',
    'Ospedale',
    'Policlinico',
    'Analisi',
    'Centro diagnostico',
    'Ginecologia',
    'Sport',
    'Fitness',
    'Scuola di arti marziali',
    'Scuola di sport',
    'Scuola di ballo',
    'Tennis e squash',
    'Piscina',
    'SME',
    'Allenatore privato',
    'Centro trampolino',
    'Yoga',
    'Formazione',
    'Scuola guida',
    'Sezione bambini',
    'Tutor',
    'Scuola di Musica',
    'Scuola di lingue',
    'Tempo libero e ricreazione',
    'Sala massaggi',
    'Missioni',
    'Sauna',
    'Bowling',
    'Cybersport',
    'Ristorante',
    'Grotte di sale',
    'Karting',
    'Divertimento',
    'Servizi domestici',
    'Studio fotografico',
    'Centro Servizi',
    'Noleggio',
    'Studio',
    'Lavaggio a secco',
    'Pulizia',
    'Interior design',
    "Riparazione dell'attrezzatura",
    'Servizi per animali domestici',
    'Autolavaggio',
    'Servizio auto',
    'Montaggio pneumatici',
    'Autosalone',
    'Auto-studio',
    'Centro di sintonia',
    'Dettagli',
    'Meccanico privato',
    'Mobili e interni',
    'Centro commerciale',
    'Boutique e show-room',
    'Salone da sposa',
    'Studio legale',
    'Ufficio legale',
    'Coworking',
    'Contabilità e revisione contabile',
    'Organizzazione di eventi',
    'Notaio',
  ],
};

export const themes = {
  white1: ['#ba0404', '#ef8d8d', '#797979'],
  dark1: ['#900505', '#8d97ef', '#152786'],
};
export const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
export const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export const tableXScroll = 300;
export const minTableCellWidth = 150;
export const smallTableCellWidth = 50;
