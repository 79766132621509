export interface IEnv {
  apiUrl: string;
  captchaKey: string;
}

const development: IEnv = {
  apiUrl: `http://${window.location.hostname}:5000/api/v1/`,
  captchaKey: '6LeW-7QZAAAAADgOuSb5FAZrH0EztZJdh6S4lU0n',
};

const production: IEnv = {
  apiUrl: `https://api.skeedee.com/api/v1/`,
  captchaKey: '6LfcVb8aAAAAACyuHHaEs-NzhdZuna4GAQp2z0Kz',
};

const environments: { [key: string]: IEnv } = {
  development,
  production,
};

export const env: IEnv = environments[process.env.NODE_ENV] || production;
